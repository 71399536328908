import styled from "styled-components"
import { device } from "../theme"

export const SectionWrap = styled.section`
  padding-top: 100px;
  margin-bottom: 20px;
  @media ${device.medium} {
    padding-top: 80px;
    margin-bottom: 75px;
  }
  @media ${device.small} {
    padding-top: 60px;
    margin-bottom: 60px;
  }
`
