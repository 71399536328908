import React from "react"
import { Container, Row, Col } from "../components/UI/wrapper"
import { graphql } from "gatsby"
import { SectionWrap } from "./producto-style"
import SingleProduct from "../containers/productos/single-product"
import SEO from "../components/seo"
import Sidebar from "../containers/productos/sidebar"

export const query = graphql`
  query ProductQuery($uid: String) {
    prismicProducto(uid: { eq: $uid }) {
      uid
      data {
        titulo {
          text
        }
        contenido {
          html
        }
        foto {
          fixed {
            src
            srcSet
          }
        }
        marca {
          document {
            ... on PrismicMarca {
              data {
                image {
                  fixed {
                    ...GatsbyPrismicImageFixed
                  }
                }
                marca {
                  text
                }
              }
            }
          }
        }
        off_road {
          text
        }
        on_road {
          text
        }
        tags {
          document {
            ... on PrismicTag {
              data {
                nombre {
                  text
                }
              }
            }
          }
        }
        categoria {
          document {
            ... on PrismicCategoria {
              data {
                nombre {
                  text
                }
                foto {
                  fixed(width: 93) {
                    ...GatsbyPrismicImageFixed
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicProductoBodyDimensiones {
            items {
              col1 {
                text
              }
              col2 {
                text
              }
              col3 {
                text
              }
            }
            primary {
              col1 {
                text
              }
              col2 {
                text
              }
              col3 {
                text
              }
            }
          }
        }
      }
    }
  }
`

const Producto = props => {
  return (
    <>
      <SEO title="producto" />
      <SectionWrap>
        <Container>
          <Row>
            <Col lg={{ span: 4, order: 1 }} xs={{ span: 12, order: 2 }}>
              <Sidebar />
            </Col>
            <Col lg={{ span: 8, order: 2 }} xs={{ span: 12, order: 1 }}>
              <SingleProduct
                nombre={props.data.prismicProducto.data.titulo.text}
                fotoSrc={props.data.prismicProducto.data.foto.fixed.src}
                fotoSrcSet={props.data.prismicProducto.data.foto.fixed.srcSet}
                contenido={props.data.prismicProducto.data.contenido.html}
                categoria={
                  props.data.prismicProducto.data.categoria.document.data.nombre
                    .text
                }
                categoriaIcon={
                  props.data.prismicProducto.data.categoria.document.data.foto
                    .fixed
                }
                marca={
                  props.data.prismicProducto.data.marca.document.data.marca.text
                }
                logoMarca={
                  props.data.prismicProducto.data.marca.document?.data.image
                    .fixed
                }
                onRoad={props.data.prismicProducto.data.on_road?.text}
                offRoad={props.data.prismicProducto.data.off_road?.text}
                dimensiones={props.data.prismicProducto.data.body[0]}
                tags={
                  props.data.prismicProducto.data.tags.document.data.nombre.text
                }
              />
            </Col>
          </Row>
        </Container>
      </SectionWrap>
    </>
  )
}

export default Producto
