import React from "react"
import PropTypes from "prop-types"
import Heading from "../../../components/Heading"
import Img from "gatsby-image"
import { FaTags } from "react-icons/fa"
import { Row, Col } from "../../../components/UI/wrapper"
import InnerImageZoom from "react-inner-image-zoom"
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css"
import SectionTitle from "../../../components/UI/section-title"
import { ProductoWrap, TagWrapper, CategoriaWrapper } from "./style"

const SingleProduct = ({
  nombre,
  fotoSrc,
  fotoSrcSet,
  contenido,
  marca,
  logoMarca,
  onRoad,
  offRoad,
  dimensiones,
  tags,
  categoria,
  categoriaIcon,
  sectionTitleStyle,
}) => {
  return (
    <ProductoWrap>
      <Row>
        <Col>
          <SectionTitle
            {...sectionTitleStyle}
            title={nombre}
            subtitle={marca}
            align="left"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <InnerImageZoom
            src={fotoSrc}
            srcSet={fotoSrcSet}
            zoomScale="1.6"
            alt="foto"
          />
        </Col>
        <Col>
          {logoMarca && <Img fixed={logoMarca} />}
          <div
            class="contenido"
            dangerouslySetInnerHTML={{ __html: contenido }}
          />
          {onRoad && offRoad && (
            <table class="table table-striped text-center">
              <thead class="thead-dark">
                <tr>
                  <th>ON ROAD (PISTA)</th>
                  <th>OFF ROAD (TROCHA)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{onRoad}</td>
                  <td>{offRoad}</td>
                </tr>
              </tbody>
            </table>
          )}
          <CategoriaWrapper>
            <Img fixed={categoriaIcon} />
            <h6>Categoría: {categoria}</h6>
          </CategoriaWrapper>
          <TagWrapper>
            <FaTags className="icon" />
            {tags}
          </TagWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <Heading as="h4" content="Dimensiones" />
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th>{dimensiones.primary.col1.text}</th>
                <th>{dimensiones.primary.col2.text}</th>
                <th>{dimensiones.primary.col3.text}</th>
              </tr>
            </thead>
            <tbody>
              {dimensiones.items.map(item => (
                <>
                  <tr>
                    <td>{item.col1.text}</td>
                    <td>{item.col2.text}</td>
                    <td>{item.col3.text}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </ProductoWrap>
  )
}

SingleProduct.propTypes = {
  sectionStyle: PropTypes.object,
  sectionTitleStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  textStyle: PropTypes.object,
  descStyle: PropTypes.object,
}

SingleProduct.defaultProps = {
  sectionTitleStyle: {
    mb: "70px",
    responsive: {
      small: {
        mb: "30px",
      },
    },
  },
  headingStyle: {
    as: "h3",
    layout: "highlight",
    maxwidth: "330px",
    mb: "24px",
    responsive: {
      medium: {
        maxwidth: "100%",
      },
    },
  },
  textStyle: {
    as: "h4",
    fontSize: "20px",
  },
  descStyle: {
    fontSize: "18px",
    lineHeight: 1.67,
    mb: "23px",
  },
  btnStyle: {
    varient: "texted",
    iconname: "far fa-long-arrow-right",
    hover: "false",
  },
}

export default SingleProduct
